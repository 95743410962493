<template>
    <div>
        <div>

            <div style="display: flex;justify-content: flex-end;padding-top: 20px">
                <el-button type="primary" @click="showAdd">生成兑换码</el-button>
            </div>

            <!--内容-->
            <div style="padding-top: 50px" v-loading="loading">
                <el-table
                        :data="list"
                        border
                        style="width: 100%">
                    <el-table-column
                            prop="exchangeCode"
                            label="兑换码"
                            show-overflow-tooltip
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="amount"
                            label="金额"
                            show-overflow-tooltip
                            align="center">
                    </el-table-column>

                    <el-table-column
                            prop="createTime"
                            label="生成时间"
                            show-overflow-tooltip
                            align="center">
                    </el-table-column>

                    <el-table-column
                            align="center"
                            fixed="right"
                            label="操作">
                        <template slot-scope="scope">
                            <el-button id="copy" type="text" @click="fuzhi(scope.row.exchangeCode)">复制兑换码</el-button>
                            <el-button type="text" @click="showRecord(scope.row.id)">兑换记录</el-button>
                            <el-button type="text" @click="deleteById(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!--分页-->
            <div style="padding-top: 50px;text-align: center">
                <el-pagination
                        class="pagiantion"
                        :total="total"
                        @current-change="handleSelectionChange"
                        :current-page="shahe.pageIndex"
                        :page-size="shahe.pageSize"
                        layout="total, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>

        </div>

        <el-dialog
                title="生成兑换码"
                :visible.sync="centerDialogVisible"
                width="20%"
                center>
            <div style="text-align: center">
                <el-input placeholder="请输入兑换金额" v-model="amount" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                          clearable></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submit">确 定</el-button>
  </span>
        </el-dialog>

        <el-dialog
                title="兑换详情"
                :visible.sync="centerDialogVisible2"
                width="30%"
                center>
            <div style="text-align: center">
                <el-table
                        :data="recordList"
                        border
                        style="width: 100%">
                    <el-table-column
                            prop="user.registerMail"
                            label="账号"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="amount"
                            label="额度"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="createTime"
                            align="center"
                            label="兑换时间">
                    </el-table-column>
                </el-table>
            </div>
            <!--            <span slot="footer" class="dialog-footer">-->
            <!--    <el-button @click="centerDialogVisible2 = false">取 消</el-button>-->
            <!--    <el-button type="primary" @click="submit">确 定</el-button>-->
            <!--  </span>-->
        </el-dialog>

    </div>
</template>

<script>
    export default {
        name: "Shahe",
        data() {
            return {
                list: [],
                loading: false,
                total: 0,
                shahe: {
                    pageIndex: 1,
                    pageSize: 5
                },
                centerDialogVisible: false,
                centerDialogVisible2: false,
                amount: null,
                recordList: []
            }
        },
        methods: {
            showRecord(id) {
                this.axios.get('/exchange/getRecordList?id=' + id).then(resp => {
                    if (resp.data.code == 200) {
                        this.recordList = resp.data.data;
                    }
                }).finally(() => {
                    this.centerDialogVisible2 = true;
                })
            },
            deleteById(id) {
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.get('/exchange/deleteById?id=' + id).then(resp => {
                        if (resp.data.code == 200) {
                            this.$message.success('删除成功')
                            this.findPage();
                        }
                    })
                })
            },
            submit() {
                if (!this.amount || this.amount.trim() == '') {
                    this.$message.warning('请输入兑换金额')
                    return false;
                }
                this.axios.get('/exchange/save?amount=' + this.amount).then(resp => {
                    if (resp.data.code == 200) {
                        this.centerDialogVisible = false
                        this.$message.success('成功生成兑换码')
                        this.findPage();
                    }
                })
            },
            showAdd() {
                this.amount = null;
                this.centerDialogVisible = true;
            },
            fuzhi(val) {
                //创建input标签
                var input = document.createElement('input')
                //将input的值设置为需要复制的内容
                input.value = val;
                //添加input标签
                document.body.appendChild(input)
                //选中input标签
                input.select()
                //执行复制
                document.execCommand('copy')
                //成功提示信息
                this.$message.success('复制成功')
                //移除input标签
                document.body.removeChild(input)
            },
            handleSelectionChange(currentPage) {
                this.shahe.pageIndex = currentPage;
                this.findPage();
            },
            findPage() {
                this.loading = true
                this.axios.post('/exchange/findPage', this.shahe).then(resp => {
                    if (resp.data.code == 200) {
                        this.list = resp.data.data.data;
                        this.total = resp.data.data.total;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
        },
        created() {
            this.findPage();
        }
    }
</script>

<style scoped>
    .el-input {
        width: 300px;
    }
</style>
